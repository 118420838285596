<template>
  <div class="data">
    <div class="lotteries container">
      <swiper ref="swiper" :options="swiperOptions" class="swiper" v-if="lotteries">
        <swiper-slide class="slide" v-for="lottery in lotteries" :key="lottery.id"
          :style="{ width: getW(lottery.list.list) }">
          <div class="lottery">
            <!-- <img :src="lottery.http_logo"> -->
            <p>{{ lottery.name }}</p>
          </div>
          <div class="data-box">
            <div>
              <router-link tag="div" :to="item.type | route(lottery.code, lottery.id)" v-for="item in lottery.list.list"
                :key="item.type">{{ item.name }}</router-link>
            </div>
          </div>
          <div class="lottery"></div>
        </swiper-slide>
        <div class="prev" slot="button-prev" @click="swiperPrev">
          <div>
            <img src="@assets/pc/menu_left.png" />
          </div>
        </div>
        <div class="next" slot="button-next">
          <div>
            <img src="@assets/pc/menu_right.png" />
          </div>
        </div>
      </swiper>
    </div>
    <div class="table container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@plugins/swiper'
import routeType from '@plugins/routeType'

export default {
  name: 'Data',
  props: {
    category: {
      type: String,
      default: 'data'
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  filters: {
    route(val, code, id) {
      return '/lottery/' + routeType(val) + '/' + code + '/' + id
    }
  },
  data() {
    return {
      lotteries: null,
      tables: [
        {
          label: '历史开奖',
          key: 'history'
        },
        {
          label: '历史统计',
          key: 'statistics'
        },
        {
          label: '冷热分析',
          key: 'analyse'
        },
        {
          label: '遗漏统计',
          key: 'omit'
        },
        {
          label: '今日号码',
          key: 'today'
        },
        // {
        //   label: '号码跟随',
        //   key: 'follow'
        // },
        {
          label: '特码分析',
          key: 'tema'
        },
        {
          label: '每日长龙',
          key: 'long'
        }
      ],
      swiperIndex: 0,
      swiperOptions: {
        navigation: {
          nextEl: '.next div',
          // prevEl: '.prev div'
        },
        slidesPerView: 'auto',
        spaceBetween: 20,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: true,
        shortSwipes: true,
        on: {
          slideChange: this.swiperChange
        }
      }
    }
  },
  computed: {
    swiper() {
      if (!this.$refs['swiper']) return null
      return this.$refs.swiper.$swiper
    }
    // lotteries () {
    //   const lotteries = this.$store.getters['lottery/list']
    //   if (lotteries && lotteries.length > 0) {
    //     const path = '/lottery/data'
    //     if (this.$route.path === path) {
    //       this.$router.replace('/lottery/data/' + this.tables[0].key + '/' + lotteries[0].code)
    //     }
    //   }
    //   return lotteries
    // }
  },
  watch: {
    category(val, oldVal) {
      this.lotteries = null
      this.requestGetAllCate()
    }
  },
  mounted() {
    this.requestGetAllCate()
  },
  beforeRouteUpdate(to, from, next) {
    if (['Data', 'LZ', 'Trend', 'Recommend'].indexOf(to.name) !== -1 && to.params.code === this.code && this.lotteries) {
      const code = this.lotteries[0].code
      const id = this.lotteries[0].id
      const type = this.lotteries[0].list.list[0].type
      var path = '/lottery/' + routeType(type) + '/' + code + '/' + id
      next({ path, replace: true })
    } else {
      next()
    }
  },
  methods: {
    swiperChange(data) {
      const { activeIndex } = data
      this.swiperIndex = activeIndex
    },
    swiperPrev() {
      if (this.$refs['swiper']) {
        this.$refs.swiper.$swiper.slideTo(this.swiperIndex)
        this.swiperIndex -= 1
        this.swiperIndex = this.swiperIndex < 0 ? 0 : this.swiperIndex
      }
    },
    getW(obj) {
      let w = 75
      const padw = 25
      if (this.$refs['swiper']) this.$refs['swiper'].updateSwiper()
      if (Object.prototype.toString.call(obj) == '[object Array]' && obj.length > 0) {
        let n = obj.length / 2
        if (n > 3) {
          return parseFloat((w * 4 + padw).toFixed(4)) + 'px';
        }

        if (n > 2 && n <= 3) {
          return parseFloat((w * 3 + padw).toFixed(4)) + 'px';
        }

        if (n > 1 && n <= 2) {
          return parseFloat((w * 2 + padw).toFixed(4)) + 'px';
        }

        if (n <= 1) {
          return parseFloat((w * 1 + padw).toFixed(4)) + 'px';
        }
      }
      return parseFloat((w * 4 + padw).toFixed(4)) + 'px';
    },
    requestGetAllCate() {
      this.$loading.show()
      this.$api.allCategory(this.category).then(res => {
        let data = res.data
        for (let i in data) {
          const pItem = data[i]
          const obj = pItem['list']
          if (obj) {
            const list = obj['list']
            if (list) {
              pItem.list.list = list.map(item => {
                if (item.name.indexOf('五形') !== -1) item['name'] = item.name.replace('五形', '五行')
                return item
              })
              if (this.category === 'road_bead') {
                pItem.list.list = list.filter(item => {
                  return item.type != 'number_road_bead' && item.type != 'sum_road_bead'
                })
              }
            }
          }
        }
        this.lotteries = data
        this.$loading.hide()
        this.replaceRouter()
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    },
    replaceRouter() {
      if (['Data', 'LZ', 'Trend', 'Recommend'].indexOf(this.$route.name) !== -1) {
        const code = this.lotteries[0].code
        const id = this.lotteries[0].id
        const type = this.lotteries[0].list.list[0].type
        this.$router.replace('/lottery/' + routeType(type) + '/' + code + '/' + id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
@import '~swiper/swiper-bundle.css';

.data {
  padding-top: 30px;

  >.lotteries {
    margin-bottom: 30px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    >div {
      width: 100%;
      height: auto;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .swiper {
      width: auto;
      height: 122px;
      box-sizing: border-box;
      display: flex;

      .slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        >.lottery {
          flex-shrink: 0;
          width: 100%;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          font-weight: bold;
          color: #222;
          text-align: center;
          background-color: #f7f7f7;

          >img {
            flex-shrink: 0;
            margin-bottom: 5px;
            width: 83px;
            height: auto;
          }
        }

        >.data-box {
          padding: 0 12.5px;
          width: 100%;
          height: 92px;
          box-sizing: border-box;
          background-color: #fff;

          >div {
            padding: 10px 0;
            width: 100%;
            height: 100%;
            box-sizing: content-box;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: flex-start;

            >div {
              width: 75px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              font-size: 14px;
              color: #222;
              cursor: pointer;

              &:hover {
                color: #fa3e3e;
              }

              &.active,
              &.router-link-exact-active {
                color: #FA3E3E;
                font-weight: bold
              }
            }
          }
        }
      }

      .prev,
      .next {
        width: 30px;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        z-index: 998;

        div {
          display: none;
          position: absolute;
          width: 100%;
          height: 52px;
          top: 50%;
          margin-top: -26px;
          z-index: 2;

          >img {
            margin-top: 26px;
            width: auto;
            height: 28px;
          }
        }
      }

      .prev:hover,
      .next:hover {
        >div {
          display: block;
        }
      }

      .prev {
        left: 0;
      }

      .next {
        right: 0;
        text-align: right;

        >span {
          width: 12px;
          height: 100%;
          background-color: #fff;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }
      }
    }
  }

  >.table {
    padding-bottom: 40px;
    overflow: hidden;

    >div {
      background-color: #fff;
      padding-bottom: 22px;
    }
  }
}

.swiper-hide-class {
  opacity: 0;
}
</style>
